import { Component, LOCALE_ID, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { SetScreenSize } from '../../projects/lib-util/src/layout/state/layout.actions';
import { SettingsService } from '@LG_CORE/settings/settings.service';
import { serialUnsubscriber } from '@LIB_UTIL/util/rx';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { LocaleID, isRtl } from '@LIB_UTIL/util/locale';
import dayjs from 'dayjs';
// import each language that MyLetsGrow supports
import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';
import 'dayjs/locale/ja';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import week from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

@Component({
  selector: 'app-root',
  template: ` <div>
    <router-outlet></router-outlet>
  </div>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private store: Store = inject(Store);
  private authService: AuthService = inject(AuthService);
  private settingsService: SettingsService = inject(SettingsService);
  private locale: LocaleID = inject(LOCALE_ID) as LocaleID;

  private subs: Record<string, Subscription> = {};
  public isRtl: boolean = isRtl(this.locale);

  public ngOnInit(): void {
    dayjs.extend(localizedFormat);
    dayjs.extend(isoWeek);
    dayjs.extend(week);
    dayjs.locale(this.locale);

    this.authService.initAuth();
    this.settingsService.initSettings();

    this.subs['windowResize'] = this.createWindowResizeSubscription();
  }

  private createWindowResizeSubscription(): Subscription {
    return fromEvent(window, 'resize', { passive: true })
      .pipe(
        startWith(window.innerWidth),
        debounceTime(200),
        map(() => window.innerWidth),
        distinctUntilChanged()
      )
      .subscribe((width: number) => this.store.dispatch(new SetScreenSize(width)));
  }

  public ngOnDestroy(): void {
    serialUnsubscriber(...Object.values(this.subs));
  }
}
